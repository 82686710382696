import store from '@/store';
import { keep2Decimal, keep4Decimal, keepLetter2AndNumber5 } from '@assets/js/regExUtil';
import { getDateNoTime } from '@assets/js/dateUtils';

export const priceProperties = [
  store.state.selection,
  store.state.index,
  { label: '咨询单编号', prop: 'inquiry_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '系列名称', prop: 'series_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '销售部门', prop: 'scon_dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '120px', input: true, sortable: false },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    input: false,
    sortable: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '备注', prop: 'inquiry_remark', itemType: 'input', input: false, sortable: false, widthAuto: true, overflowTooltip: true },
  {
    prop: 'order_status',
    label: '报价状态',
    itemType: 'select',
    formatter: val => (val === 0 ? '未报价' : val === 1 ? '报价中' : val === 2 ? '已报价' : '暂无'),
    options: [
      { value: 0, label: '未报价' },
      { value: 1, label: '报价中' },
      { value: 2, label: '已报价' }
    ]
  }
];

export const orderProperties = [
  store.state.selection,
  store.state.index,
  { label: '咨询单编号', prop: 'inquiry_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '系列名称', prop: 'series_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户订单号', prop: 'cust_order_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '销售部门', prop: 'scon_dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '120px', input: true, sortable: false },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    input: false,
    sortable: true,
    formatter: val => getDateNoTime(val, false),
    widthAuto: true
  },
  { label: '备注', prop: 'inquiry_remark', itemType: 'input', input: false, sortable: false, widthAuto: true, overflowTooltip: true },
  {
    prop: 'order_status',
    label: '报价状态',
    itemType: 'select',
    formatter: val => (val === 0 ? '未报价' : val === 1 ? '报价中' : val === 2 ? '已报价' : '暂无'),
    options: [
      { value: 0, label: '未报价' },
      { value: 1, label: '报价中' },
      { value: 2, label: '已报价' }
    ]
  }
];

export const inquiryFrom = [
  {
    label: '客户简称',
    prop: 'cust_id',
    itemType: 'select',
    model: '',
    size: 'small',
    filterable: true,
    rules: { required: true, message: ' ' },
    options: [],
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '系列名称',
    prop: 'series_name',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 100,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '销售部门',
    prop: 'scon_dept_id',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '备注',
    prop: 'inquiry_remark',
    split: 24,
    model: '',
    rows: 3,
    type: 'textarea',
    itemType: 'input',
    size: 'small',
    maxLength: 500,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  }
];
export const inquiry2From = [
  {
    label: '客户简称',
    prop: 'cust_id',
    itemType: 'select',
    model: '',
    size: 'small',
    filterable: true,
    rules: { required: true, message: ' ' },
    options: [],
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '客户订单号',
    prop: 'cust_order_no',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 20,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '系列名称',
    prop: 'series_name',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 100,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '销售部门',
    prop: 'scon_dept_id',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '备注',
    prop: 'inquiry_remark',
    split: 24,
    model: '',
    rows: 3,
    type: 'textarea',
    itemType: 'input',
    size: 'small',
    maxLength: 500,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  }
];

export const goodsTableProperties = [
  {
    label: '产品图片',
    prop: 'imge_url',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '220',
    subItem: { type: 'uploadImage', image: row => row.imge_url, thumbnail: row => row.imge_url }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '180',
    subItem: { required: true, type: 'input', input: (val, row) => (row.prod_no = keepLetter2AndNumber5(val)), maxLength: 7, disabled: row => !row.isNew }
  },
  {
    label: '更改号',
    prop: 'prod_suffix',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', disabled: row => !row.isNew, maxLength: 5 }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', maxLength: 20, disabled: row => !row.isGenerate }
  },
  {
    label: '中文名称',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', maxLength: 20 }
  },
  {
    label: '英文名称',
    prop: 'prod_ename',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', maxLength: 100 }
  },
  {
    label: '委托打样单号',
    prop: 'requ_smpl_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', disabled: row => !row.isNew, maxLength: 10 }
  },
  {
    label: '询价人',
    prop: 'scon_stff_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    needOtherColumn: true,
    formatter: row => row.scon_stff_name ?? '暂无',
    subItem: {
      type: 'select',
      options: []
    }
  },
  {
    label: '预计数量',
    prop: 'estimat_number',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', input: (val, row) => (row.estimat_number = keep2Decimal(val)), maxLength: 12 }
  },
  {
    label: '预计交期',
    prop: 'delivery_time',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date', copyAndPaste: true }
  },
  {
    label: '样品核价',
    prop: 'total_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    needOtherColumn: true,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '目标价',
    prop: 'target_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', required: false, input: (val, row) => (row.target_price = keep4Decimal(val)), maxLength: 9 }
  },
  {
    label: '主商品采购部门',
    prop: 'purch_dept_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: row => (row === 6 ? '采购一部' : row === 7 ? '采购二部' : '暂无'),
    subItem: {
      type: 'select',
      options: [
        { value: 6, label: '采购一部' },
        { value: 7, label: '采购二部' }
      ],
      required: true,
      copyAndPaste: true
    }
  },
  {
    label: '产品采购总价',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { disabled: true, type: 'input' }
  }
];

export const partsTableProperties = [
  {
    label: '产品图片',
    prop: 'imge_url',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '220',
    subItem: { type: 'uploadImage', image: row => row.imge_url, thumbnail: row => row.imge_url }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '更改号',
    prop: 'prod_suffix',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '询价人',
    prop: 'scon_stff_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    needOtherColumn: true
  },
  {
    label: '中文名称',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '部件名称',
    prop: 'prod_partno',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', maxLength: 20 }
  },
  {
    label: '包装/送货要求',
    prop: 'pack_ement',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'popoverInput', maxLength: 500 }
  },
  {
    label: '产品描述',
    prop: 'part_describe',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'popoverInput', maxLength: 500 }
  },
  {
    label: '用量',
    prop: 'prod_use',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', input: (val, row) => (row.prod_use = keep4Decimal(val)), maxLength: 7 }
  },
  {
    label: '预计数量',
    prop: 'estimat_number',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', input: (val, row) => (row.estimat_number = keep2Decimal(val)), maxLength: 12 }
  },
  {
    label: '预计交期',
    prop: 'delivery_time',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date', copyAndPaste: true }
  },
  {
    label: '目标价',
    prop: 'target_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', required: false, input: (val, row) => (row.target_price = keep4Decimal(val)), maxLength: 9 }
  },
  {
    label: '采购部门',
    prop: 'purch_dept_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: row => (row === 6 ? '采购一部' : row === 7 ? '采购二部' : '暂无'),
    subItem: {
      type: 'select',
      options: [
        { value: 6, label: '采购一部' },
        { value: 7, label: '采购二部' }
      ],
      required: true,
      copyAndPaste: true
    }
  },
  {
    label: '备注',
    prop: 'remarks',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'popoverInput' }
  },
  {
    label: '采购备注',
    prop: 'pord_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'popoverInput', disabled: true }
  },
  {
    label: '采购确认价',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '采购沟通交期',
    prop: 'clear_time',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '操作详情',
    prop: 'operation',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  }
];

export const goodsMoreTableProperties = [
  {
    label: '产品图片',
    prop: 'imge_url',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '220',
    subItem: { type: 'uploadImage', image: row => row.imge_url, thumbnail: row => row.imge_url }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '180',
    subItem: { required: true, input: (val, row) => (row.prod_no = keepLetter2AndNumber5(val)), type: 'input', maxLength: 10, disabled: row => !row.isNew }
  },
  {
    label: '更改号',
    prop: 'prod_suffix',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', disabled: row => !row.isNew, maxLength: 5 }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', required: true, maxLength: 20, disabled: row => !row.isGenerate }
  },
  {
    label: '中文名称',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', required: true, maxLength: 20 }
  },
  {
    label: '英文名称',
    prop: 'prod_ename',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', required: true, maxLength: 100 }
  },
  {
    label: '委托打样单号',
    prop: 'requ_smpl_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', disabled: row => !row.isNew, maxLength: 10 }
  },
  {
    label: '询价人',
    prop: 'scon_stff_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    needOtherColumn: true,
    formatter: row => row.scon_stff_name ?? '暂无',
    subItem: {
      type: 'select',
      options: [],
      change: (val, row, options) => (row.scon_stff_name = options.find(({ value }) => value === val).label)
    }
  },
  {
    label: '主商品采购部门',
    prop: 'purch_dept_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: row => (row === 6 ? '采购一部' : row === 7 ? '采购二部' : '暂无'),
    subItem: {
      type: 'select',
      options: [
        { value: 6, label: '采购一部' },
        { value: 7, label: '采购二部' }
      ],
      required: true,
      copyAndPaste: true
    }
  },
  {
    label: '单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'select', options: [], required: true, copyAndPaste: true }
  },
  {
    label: '订单数量',
    prop: 'estimat_number',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', input: (val, row) => (row.estimat_number = keep2Decimal(val)), maxLength: 10, required: true }
  },
  {
    label: '要求交期',
    prop: 'delivery_time',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date', required: true, copyAndPaste: true }
  },
  {
    label: '样品核价',
    prop: 'total_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    needOtherColumn: true,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '产品采购总价',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { disabled: true, type: 'input' }
  },
  {
    label: '备注',
    prop: 'remarks',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'popoverInput' }
  },
  {
    label: '操作',
    prop: 'generateProductInfo',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  }
];

export const partsMoreTableProperties = [
  {
    label: '产品图片',
    prop: 'imge_url',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '220',
    subItem: { type: 'uploadImage', image: row => row.imge_url, thumbnail: row => row.imge_url }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '更改号',
    prop: 'prod_suffix',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '询价人',
    prop: 'scon_stff_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    needOtherColumn: true
  },
  {
    label: '中文名称',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '部件名称',
    prop: 'prod_partno',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', required: true, maxLength: 20 }
  },
  {
    label: '是否主商品',
    prop: 'prod_mainsup',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      type: 'newSelect',
      options: {
        1: { label: '是', value: 1 },
        0: { label: '否', value: 0 }
      },
      required: true
    }
  },
  {
    label: '包装/送货要求',
    prop: 'pack_ement',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'popoverInput', maxLength: 500 }
  },
  {
    label: '产品描述',
    prop: 'part_describe',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'popoverInput', maxLength: 500 }
  },
  {
    label: '用量',
    prop: 'prod_use',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', required: true, input: (val, row) => (row.prod_use = keep4Decimal(val)), maxLength: 9 }
  },
  {
    label: '订单数量',
    prop: 'estimat_number',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'input', disabled: true, required: true }
  },
  {
    label: '要求交期',
    prop: 'delivery_time',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date', required: true, copyAndPaste: true }
  },
  {
    label: '单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'select', options: [], required: true }
  },
  {
    label: '采购部门',
    prop: 'purch_dept_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: row => (row === 6 ? '采购一部' : row === 7 ? '采购二部' : '暂无'),
    subItem: {
      type: 'select',
      options: [
        { value: 6, label: '采购一部' },
        { value: 7, label: '采购二部' }
      ],
      required: true,
      copyAndPaste: true
    }
  },
  {
    label: '备注',
    prop: 'remarks',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { type: 'popoverInput' }
  },
  {
    label: '采购备注',
    prop: 'pord_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { disabled: true, type: 'popoverInput' }
  },
  {
    label: '采购确认价',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { disabled: true, type: 'input' }
  },
  {
    label: '采购沟通交期',
    prop: 'clear_time',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    formatter: val => getDateNoTime(val, false),
    subItem: { disabled: true, type: 'date' }
  },
  {
    label: '确认供应商',
    prop: 'supp_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: { disabled: true, type: 'input' }
  },
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '220'
  }
];
export const subTableProperties = [
  { label: '', prop: 'pricing_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '成本价', prop: 'pricing_price', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '税点', prop: 'tax_points', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '含税金额', prop: 'includtax_money', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '税额合计', prop: 'taxamount_total', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const otherTableProperties1 = [
  { label: '物料编号', prop: 'mtrl_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '物料类型',
    prop: 'mtrl_type',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => (val === 0 ? '原面料' : '加工面料')
  },
  { label: '物料名称', prop: 'mtrl_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '物料规格', prop: 'mtrl_spec', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '部位', prop: 'quta_mtrl_part', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '用量', prop: 'quta_mtrl_consume', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '损耗比率', prop: 'quta_mtrl_lossrate', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '含损耗用量', prop: 'quta_consumn_loss', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '单价', prop: 'mtrl_price', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '金额', prop: 'quta_money', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const otherTableProperties2 = [
  { label: '名称', prop: 'mtrl_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '规格', prop: 'mtrl_spec', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '用量', prop: 'quta_mtrl_consume', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '耗损比率', prop: 'quta_mtrl_lossrate', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '单价', prop: 'mtrl_price', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '金额', prop: 'quta_money', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const otherTableProperties3 = [
  { label: '工序', prop: 'name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '数值', prop: 'num', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '单位', prop: 'unit', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '单价(分钟/元)', prop: 'sala', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '金额', prop: 'money', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const otherTableProperties4 = [
  { label: '配件名称', prop: 'quta_part_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '配件规格', prop: 'quta_part_spec', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '配件重量', prop: 'quta_part_weight', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '配件价格', prop: 'quta_part_price', itemType: 'input', input: false, sortable: false, widthAuto: true }
];

export const dialog1Properties = [
  store.state.selection,
  store.state.index,
  { label: '我司货号', prop: 'smpl_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '更改号', prop: 'prod_suffix', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '核价单号', prop: 'quta_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '打样工厂', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
export const dialog2Properties = [
  store.state.selection,
  store.state.index,
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '更改号', prop: 'prod_suffix', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '委托打样单号', prop: 'requ_smpl_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '二部报价单号', prop: 'qutb_no', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
export const dialog3Properties = [
  store.state.selection,
  store.state.index,
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '更改号', prop: 'prod_suffix', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
export const dialog4Properties = [
  store.state.selection,
  store.state.index,
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '打样委托人', prop: 'scon_stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '更改号', prop: 'prod_suffix', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '工厂简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '委托打样单号', prop: 'requ_smpl_no', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
export const dialog5Properties = [
  store.state.index,
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '中文名称', prop: 'prod_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '英文名称', prop: 'prod_ename', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '货号后缀', prop: 'prod_poststfix', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
